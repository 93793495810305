/* Extra small devices (portrait phoens, 0px and up) */
@media (min-width: 0px) {  

    .icon {
        margin-right: 8px;
        font-size: 18px;
    }
    .page-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100vh;
        width: 100vw;
        padding: 0 0px;
    }

    .hire-container {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: center;
        background-color: #111;
        opacity: 1;
        padding: 11px 15px 8px 15px;
        font-size: 1rem;
        text-decoration: none;
        border-radius: 3px;
        color: #FFF;
        letter-spacing: 0.6px;
        margin: auto 5px;
        font-family: "Passion One";
        text-transform: uppercase;
        font-weight: bold;
        text-align: center;
        width: 100%;
    }

    .messageSent {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: center;
        background-color: #111;
        opacity: 1;
        padding: 11px 15px 8px 15px;
        /* padding-top: 4px; */
        font-size: 1rem;
        text-decoration: none;
        border-radius: 3px;
        color: #FFF;
        letter-spacing: 0.6px;
        /* margin: auto 5px; */
        font-family: "Passion One";
        text-transform: uppercase;
        font-weight: bold;
        text-align: center;
        margin-top: 10px;
    }

    .sm {
        background-color: rgba(0, 0, 0, 1);
        border-radius: 3px;
    }

    .heading {
        font-size: 5rem;
        line-height: 7rem;
        color: #FFF;
        letter-spacing: 3px;
        text-shadow: 0px 0px 17px rgba(0,0,0,0.65);
        font-family: "Atlantico";
    }

    .send-message-text {
        font-size: 2rem;
        line-height: 0.6rem;
        color: #FFF;
        letter-spacing: 0.5px;
        text-shadow: 0px 0px 17px rgba(0,0,0,0.85);
        font-family: "Atlantico";
        text-align: center;
    }

    .highlight {
        color: #FFEBBC;
    }

    .form {
        display: flex;
        flex-direction: column;
        width: 100%;
    }


    .center-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .links-container {
        display: flex;
        justify-content: center;
    }

    .links-container-2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
    }

    .standard-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: center;
        background-color: #111;
        opacity: 1;
        padding: 11px 15px 8px 15px;
        font-size: 1rem;
        text-decoration: none;
        border-radius: 3px;
        color: #FFF;
        letter-spacing: 0.6px;
        margin: auto 5px;
        font-family: "Passion One";
        text-transform: uppercase;
        font-weight: bold;
        text-align: center;
    }

    .standard-btn:first-of-type {
        margin-left: 0px;
    }

    .standard-btn:last-of-type {
        margin-right: 0px;
    }

    .standard-btn:hover {
        opacity: 0.8;
        color: #FFF;
    }

    .standard-btn-instagram {
        background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
        background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
        background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
        opacity: 1;
    }

    .custom-colour-1 {
        background-color: rgba(255, 244, 255, 1);
        color: #111;
    }

    .custom-colour-1:hover {
        color: #111;
    }

    .standard-btn-instagram:hover {
        color: #FFF;
        opacity: 0.8;
    }

    .social-container {
        display: flex;
        justify-content: center;
        padding: 0px 0 0px 0;
        background-color:  rgba(0,0,0,0.9);
        width: 100%;
    }

    .social-link {
        color: #FFF;
        /* text-shadow: 0px 0px 17px rgba(0,0,0,0.65); */
        font-size: 1.6rem;
        margin: auto 8px;
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .social-link:hover {
        opacity: 0.7;
        color: #FFF;
    }
    .errors {
        color: red;
        font-family: sans-serif;
        /* text-transform: uppercase; */
        /* font-weight: bold; */
        /* text-align: center;     */
        margin-top: 5px;
        font-size: 12px;
        font-weight: 600;
        text-shadow: 0px 0px 17px rgba(0,0,0,0.65);
    }

}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 
    .heading {
        font-size: 7rem;
    }
 }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {  }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {  }

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  }

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {  }

.button {
    font-family: "Arial" !important;
    text-transform: uppercase !important;
    background-color: #111 !important;
    border-color: #111 !important;
    font-family: "Passion One" !important;
    letter-spacing: 1px !important;
    font-size: 18px !important;
}

.button:hover {
    background-color: #FFF !important;
    color: #111 !important;
}

.dropdown-menu {
    background-color: #FFF !important;
    width: 100% !important;
}

.dropdown-menu-item {
    color: #111 !important;
    font-family: "Passion One" !important;
    font-size: 24px !important;
}

.modal-header-1 {
    font-size: 18px;
    font-weight: 600;
}

.errors-2 {
    color: rgb(158, 0, 0);
    font-family: sans-serif;
    /* text-transform: uppercase; */
    /* font-weight: bold; */
    /* text-align: center;     */
    margin-top: 5px;
    font-size: 12px;
    font-weight: 600;
}

.form-label {
    margin-bottom: 4px !important;
    font-size: 14px;
    font-weight: 500;
}

.exp {
    font-weight: 400;
    font-size: 12px;
    font-style: italic;
}