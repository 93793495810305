/* Extra small devices (portrait phoens, 0px and up) */
@media (min-width: 0px) {
  body {
    background-image: url('../public/background.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 20% 30%;
    font-family: "Atlantico";
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 
  body {
    background-position: 15% 30%;
  }
 }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {  
  body {
    background-position: 10% 30%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  body {
    background-position: 0% 0%;
  }
 }

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  }

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {  }

@import url('https://fonts.googleapis.com/css2?family=Coda:wght@400;800&family=Passion+One:wght@400;700;900&display=swap');
@font-face {
  font-family: "Atlantico";
  src: local("Atlantico"), url("./fonts/atlantico.otf") format("opentype");
}