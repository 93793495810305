/* Extra small devices (portrait phoens, 0px and up) */
@media (min-width: 0px) {  

    .icon {
        margin-right: 8px;
        font-size: 18px;
    }
    .page-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100vh;
        width: 100vw;
        padding: 0 0px;
    }

    .messageSent {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: center;
        background-color: #111;
        opacity: 1;
        padding: 11px 15px 8px 15px;
        /* padding-top: 4px; */
        font-size: 1rem;
        text-decoration: none;
        border-radius: 3px;
        color: #FFF;
        letter-spacing: 0.6px;
        /* margin: auto 5px; */
        font-family: "Passion One";
        text-transform: uppercase;
        font-weight: bold;
        text-align: center;
        margin-top: 10px;
    }

    .sm {
        background-color: rgba(0, 0, 0, 1);
        border-radius: 3px;
    }

    .heading {
        font-size: 5rem;
        line-height: 7rem;
        color: #FFF;
        letter-spacing: 3px;
        text-shadow: 0px 0px 17px rgba(0,0,0,0.65);
        font-family: "Atlantico";
    }

    .send-message-text {
        font-size: 2rem;
        line-height: 0.6rem;
        color: #FFF;
        letter-spacing: 0.5px;
        text-shadow: 0px 0px 17px rgba(0,0,0,0.85);
        font-family: "Atlantico";
        text-align: center;
    }

    .highlight {
        color: #FFEBBC;
    }

    .form {
        display: flex;
        flex-direction: column;
        width: 100%;
    }


    .center-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .links-container {
        display: flex;
        justify-content: center;
    }

    .standard-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: center;
        background-color: #111;
        opacity: 1;
        padding: 11px 15px 8px 15px;
        font-size: 1rem;
        text-decoration: none;
        border-radius: 3px;
        color: #FFF;
        letter-spacing: 0.6px;
        margin: auto 5px;
        font-family: "Passion One";
        text-transform: uppercase;
        font-weight: bold;
        text-align: center;
    }

    .standard-btn:first-of-type {
        margin-left: 0px;
    }

    .standard-btn:last-of-type {
        margin-right: 0px;
    }

    .standard-btn:hover {
        opacity: 0.8;
        color: #FFF;
    }

    .standard-btn-instagram {
        background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
        background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
        background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
        opacity: 1;
    }

    .standard-btn-instagram:hover {
        color: #FFF;
        opacity: 0.8;
    }

    .john {
        color: #FFF;
        display: flex;
        justify-content: center;
        background-color:  rgba(0,0,0,0.9);
        font-size: 12px;
    }

    .social-container-outer {
        display: flex;
        flex-direction:column;
        justify-content: center;
        padding: 12px 0 8px 0;
        background-color:  rgba(0,0,0,0.9);
        width: 100%;
    }

    .social-container {
        display: flex;
        justify-content: center;
        padding: 12px 0 8px 0;
        background-color:  rgba(0,0,0,0.9);
        width: 100%;
    }

    .social-link {
        color: #111;
        text-shadow: 0px 0px 17px rgba(0,0,0,0.65);
        font-size: 1.6rem;
        margin: auto 8px;
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .social-link:hover {
        opacity: 0.7;
        color: #FFF;
    }
    .errors {
        color: red;
        font-family: sans-serif;
        /* text-transform: uppercase; */
        /* font-weight: bold; */
        /* text-align: center;     */
        margin-top: 5px;
        font-size: 12px;
        font-weight: 600;
        text-shadow: 0px 0px 17px rgba(0,0,0,0.65);
    }

}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 
    .heading {
        font-size: 7rem;
    }
 }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {  }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {  }

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  }

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {  }